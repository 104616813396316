.sign-in {
    width: 80%;
    display: block;
    justify-content: center;
    color: #4a8db7;
    font-size: 20px
}
    .title{
        margin: 10px 0;
    }

    .buttons {
        display: block;
    }
